import { useRef } from 'react';
import CreateCustomWall from  './CreateCustomWall'
import SelectYourCompostion from './SelectYourCompostion';
import SelectArtAndFrame from './SelectArtAndFrame';
import Done from './Done';
import styled from 'styled-components';
import Slider from "react-slick";
import './slick.css'

const StyledSlider = styled(Slider)`
    width:100%;
`;

const SideBar = (): JSX.Element => {
    const sliderRef =  useRef<any>(null);
    const handlerNextSlide= () => {
       if(sliderRef.current){
            sliderRef.current.slickNext();
       }
    }
    const handlerPrevSlide = () => {
      if(sliderRef.current){
           sliderRef.current.slickPrev();
      }
   }
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        swipe:false,
        slidesToScroll: 1
    };

    return (
        <StyledSlider 
            ref={sliderRef}
            {...settings}> 
            <div><CreateCustomWall nextSlide={handlerNextSlide} /></div>
            <div><SelectYourCompostion nextSlide={handlerNextSlide} prevSlide={handlerPrevSlide} /></div>
            <div><SelectArtAndFrame nextSlide={handlerNextSlide} prevSlide={handlerPrevSlide}/></div>
            <div><Done prevSlide={handlerPrevSlide}/></div>
        </StyledSlider>
    );
}
export default SideBar;
