import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header'
import SideBar from '../../components/SideBar';
import Canvas from '../../components/Canvas';
import AppContext from '../../store/context';
import CanvasSplash from '../../components/Canvas/CanvasSplash';
const AppWrapper = styled.div`
  color: #000;
`;
const AppSideBar = styled.div`
  display: flex;
  padding: 0 10px;
  width: 100%;
  max-width: 400px;
`;
const AppCanvas = styled.div`
  display: flex;
  padding: 0 10px;
  flex-grow: 1;
  background-color: #F5F5F5;
`;
const AppContent = styled.div`
  display: flex;
  width:100%;
  min-height: 93vh;
  max-height: 93vh;
  overflow:hidden;
`;

function App() {
  const {createCheckout, getProducts, state} = useContext(AppContext);
  const {products, wallImage} = state

  useEffect(() => {
    if(createCheckout()){
      const get = createCheckout()
    }
  },[createCheckout]);
  useEffect(() => {
    if(products.length === 0){
      const data =  getProducts()
    }
  },[products, getProducts])
  
  return (
    <AppWrapper>
      <Header />
      <AppContent>
        <AppSideBar>
          <SideBar />
        </AppSideBar>
        <AppCanvas>
            {wallImage ?  <Canvas /> : <CanvasSplash />}
        </AppCanvas>
      </AppContent>
    </AppWrapper>
  );
}

export default App;
