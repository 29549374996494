import { FC, useContext} from 'react';
import ProductList from '../../ProductList';
import PictureFrame  from '../../PictureFrame'
import {PictureFrame as PFModal} from '../../../api/models'
import styled from 'styled-components'
import AppContext from '../.././../store/context'
import { useEffect } from 'react';
import { useState } from 'react';

interface IProps {
    nextSlide: any;
    prevSlide: any;
};

const PictureFrameWrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
`;

const AddArtWork = styled.div`
    width: 100%;
    text-align: left;
    padding: 0 20px;
    text-decoration: underline;
    font-weight: bold;
    font-size: 0.9em;
    cursor:pointer;
    &:hover {
        color: ${props => props.theme.colors.offBlack};
     }
`;
const SelectArtAndFrameWrapper = styled.div`
    position: relative;
    padding: 20px;
`;
const SizeSelect = styled.select`
    width: 100%;
    padding: 15px 10px;
`;
const SizeTitle = styled.div`
    width: 100%;
    padding: 20px 0px 10px 0px;
`;
const CompositionBackWrapper = styled.div`
    display:flex;
    border-bottom:1px solid #D7D7D7;
    padding-bottom: 10px;
`;
const CompositionBackButton = styled.div`
    padding: 10px;
    text-align: left;
    text-decoration: underline;
    text-transform: uppercase;
    cursor:pointer;
    &:hover {
        color: ${props => props.theme.colors.offBlack};
     }
`;

const CompositionBackIcon = styled.div`
    padding: 9px 0 0 9px;   

`;

const PosterName = styled.div`
    font-size: 0.7em;
    max-width: 133px;
    padding: 10px 0px;
`;

const ButtonSave = styled.div`
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color:#000;
    color: #fff;
    cursor:pointer;
    margin-top: 15px;
    &:hover {
        color: ${props => props.theme.colors.main};
        background-color: ${props => props.theme.colors.secondary};
        border: 1px solid #000;
     }
`;


const PosterSize = (props:any) => {
    const {variants} = props;
    const colors = ["White", "Black", "Oak"];
    const [colorSizes, setColorSizes] = useState<any>([])

    const handleSelected = (size: string) => {
        const data = colors.map((name:string) => <option key={name} value={name + " " + size}>{name + " " + size}</option>)
        setColorSizes(data)
    }
    if(colorSizes.length === 0){
        handleSelected(variants[0].size)
    }
   
    return  <div>
                <SizeTitle>Poster Size</SizeTitle>
                <SizeSelect 
                    name="posterSize" 
                    id="posterSize"
                    onChange={(e) => handleSelected(e.target.value)}
                    >
                    {variants.map((varient: any) =>  <option key={varient.size} value={varient.size }>{varient.size}</option> ) }
                </SizeSelect>
                <SizeTitle>Frame Size</SizeTitle>
                <SizeSelect name="frameSize" id="frameSize">
                    <option value="noframe">No Frame</option>
                    {colorSizes.map((data:any) => data)}
                </SizeSelect>
            </div>
}

const SelectArtAndFrame: FC<IProps> = (props) => {
    const { state } = useContext(AppContext);
    const { pictureFrames, selectedFrame } = state;
    const { nextSlide, prevSlide } = props
    const [image, setImage] = useState<string>("")
    const [pictureFrame, setPictureFrame] = useState<PFModal>()
    const [isOpen, setOpen] = useState<boolean>(false)

    useEffect(() => {
        if(selectedFrame && pictureFrames.length !== 0){
            const selectedPictureFrame = pictureFrames.find(( pfModal:PFModal ) => pfModal.frameID === selectedFrame)
            if(selectedPictureFrame){
                setImage(selectedPictureFrame.product.variants[0].src)
                setPictureFrame(selectedPictureFrame)
            }
        }
    },[pictureFrames, selectedFrame])

    const handleToggleArtwork = () => {
        setOpen(!isOpen);
    }

   return   <SelectArtAndFrameWrapper>


                <CompositionBackWrapper>
                    <CompositionBackIcon>&#60;</CompositionBackIcon>
                    <CompositionBackButton onClick={prevSlide}>Select your composition</CompositionBackButton>
                </CompositionBackWrapper>




                <h2>Select Artwork And Frame</h2>




        
                    <PictureFrameWrapper>
                        <PictureFrame
                            image={image}
                            width={190}
                            height={200}
                        />
                        <AddArtWork onClick={() => handleToggleArtwork()}>
                            add artwork
                        </AddArtWork>
                    </PictureFrameWrapper>
                    {pictureFrame ? 
                        <>
                            <PosterName>{pictureFrame.product.getTitle}</PosterName> 
                            <div><PosterSize variants={pictureFrame.product.variants} /></div> 
                        </> 
                    : null}

                <ProductList isOpen={isOpen} handleToggleArtwork={() => handleToggleArtwork()}  />
                <ButtonSave onClick={nextSlide}>Confirm</ButtonSave>
            </SelectArtAndFrameWrapper>
}
export default SelectArtAndFrame;


