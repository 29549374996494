import styled, { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    main: '#000000',
    secondary: '#ffffff',
    offBlack: '#222120',
    offWhite: '#F5F5F5',
    offWhite2: '#F9F9F9',
    brown: '#757642',
    maroon: '#AC1737',
    lightMaroon: '#D0C4C4',
    lightMaroon2: '#AEA5A5',
    pink:'#F3EDED'
  },
};
export const Button = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
  color: ${theme.colors.main};
  border: 2px solid $00;
`;

export default theme;

