import { FC } from 'react';
interface IProps {
    prevSlide: any
};

const Done: FC<IProps> = (props) => {
    const { prevSlide } = props
   return   <div>
                <h2>Done</h2>
                <div onClick={prevSlide}>Back</div>
            </div>
}
export default Done;


