import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Image } from 'react-konva';

interface IWBImageProps {
    base64Image:string;
};

const WBImage: FC<IWBImageProps> = ({base64Image}) => {
    
    const [nativeImage, setNativeImage] = useState<HTMLImageElement>();
 
    useEffect(() => {
        let img = new window.Image();
        img.src = base64Image
        setNativeImage(img)
    },[base64Image])

    return  <Image
                image={nativeImage}
                width={window.innerWidth - 420}
                height={window.innerHeight - 100 }
                y={10}
                x={0}
            />
}
export default WBImage