
import { IAPIResponseModel, IProduct, IData, IVariant } from './interfaces/IAPIResponseModel';
import { Item } from './models';
import Product from './models/product'
/* eslint @typescript-eslint/no-var-requires: "off" */
const Client = require("shopify-buy/index.unoptimized.umd.min.js");
const client = Client.buildClient({
  domain: 'agood-wallbuilder.myshopify.com',
  storefrontAccessToken: 'aeff905afa52981e644ff26d8416bae5'
});

export default class API {
  async getAllProducts(): Promise<Product[]> {
    let products: Array<Product> = []
    client.product.fetchAll().then((data: any) => {
      // Do something with the products
      console.log(data);
    });
    return Promise.all(products);
  }


  async getProducts(): Promise<Product[]> {
    let products: Array<Product> = []
      if(client.graphQLClient) {
        const productsQuery = client.graphQLClient.query((root:any) => {
          root.addConnection('products', {args: {first: 10}}, (product: any) => {
            product.add('title');
            product.addConnection("variants", { args: { first: 50 } }, (variant: any) => {
              variant.add("id");
              variant.add("price");
              variant.add("sku");
              variant.add("image", (opts: any) => {
                opts.add("src");
              });
              variant.add("selectedOptions", (opts: any) => {
                opts.add("name");
                opts.add("value");
              });
            });
          })
        });
        await client.graphQLClient.send(productsQuery).then((response: IAPIResponseModel) => {
          response.model.products.forEach((product: IProduct) => {
            let data: IData[] = []
            product.variants.forEach((variant: IVariant) => {
                data.push({
                  id: variant.id,
                  src: variant.image.src,
                  price: variant.price,
                  size: variant.selectedOptions[0].value
            
                });
              });
              products.push(
                new Product(
                  product.id, 
                  product.title, 
                  data
              ));
            });
        });
      }

    return Promise.all(products);
  }
  async createCheckout(): Promise<any> {
    return client ? client.checkout.create() : {}
  }
  async addLineItems(checkoutId: string, lineItemsToAdd: Array<Item>): Promise<any> {
   // console.log("addLineItems", lineItemsToAdd)

    Promise.all([ 
      client.checkout
        .addLineItems(checkoutId, lineItemsToAdd)
        .then((checkout:any) => {
           // console.log(checkout)
          //  console.log("checkout.lineItems", checkout.lineItems); 
        })
    ]);

    return  true
  }

  async removeLineItems(checkoutId: string, lineItemIdsToRemove: Array<string>): Promise<any> {
    console.log("lineItemIdsToRemove", lineItemIdsToRemove)
    Promise.all([ 
      client.checkout
        .removeLineItems(checkoutId, lineItemIdsToRemove)
        .then((checkout:any) => {
            console.log(checkout)
            console.log("checkout.lineItems", checkout.lineItems); 
        })
    ]);

    return  true
  }


  async searchProducts(searchTerm:string): Promise<Product[]> {
    let products: Array<Product> = [];
    if(client){
      client.product.fetchQuery({first: 20, sortKey: 'CREATED_AT', reverse: true, query: `title:${searchTerm}`}).then((product:any) => {
        product.forEach((item:any) => {
            //console.log(item)
          // products.push(new Product())
        })
      });
    }
    return Promise.all(products);
  }
}

