import { FC } from 'react';
import UploadImage from './ImageUploader';
import WallSizeCalculator from './WallSizeCalculator';
interface IProps {
    nextSlide: any;
};
const CreateCustomWall: FC<IProps> = (props) => {
    const {nextSlide} = props
   return   <div>
                <h2>Design your custom wall</h2>
                <p>Follow the steps below to create and purchase your customised wall. Start by uploading a photo of your room you wish to decorate.</p>
                <h3>Upload your wall</h3>
                <UploadImage />
                <WallSizeCalculator nextSlide={nextSlide} />
            </div>
}
export default CreateCustomWall;