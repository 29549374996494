import { FC, useState } from 'react';
import { Rect, Circle,Group, Image } from 'react-konva';
import useImage from 'use-image';
import { Frame, PictureFrame } from '../../api/models';
//import useSVGImage from './SVGImage'
interface IWBFrameProps {
    frame: Frame;
    pictureFrame: PictureFrame;
    onSelected: any;
    onDismiss: any;
};

const Artwork = (props:any) => {
    const {src, width, height} = props
    const [tokenImg] = useImage(src);
 //     const svg = useSVGImage(src, width, height)
  //    const [image] = useImage("data:image/svg+xml;base64," + window.btoa(svg));
//      console.log(image)
    return   <Image
                image={tokenImg}
                width={width}
                height={height}
                crossOrigin="anonymous"
            
            />
}

///this should take in frame object
const WBFrame: FC<IWBFrameProps> = ({frame, pictureFrame, onSelected, onDismiss}) => {
    const [selected, setSelected] = useState<boolean>(false);
    let src2 = pictureFrame?.product ? pictureFrame?.product.variants[0].src  : "";
    const selectedHandler = (idSelected: string) => {
       if(onSelected){
            onSelected(frame.getID)
            setSelected(!selected);
       }
    }
    const removeHandler = (idRemove: string) => {
        if(onDismiss){
            onDismiss(idRemove)
        }
    }
    return  <Group 
                    width={frame.getWidth}
                    height={frame.getHeight} 
                    draggable={true}
                    onClick = {() => selectedHandler(frame.getID)}
                    x={frame.getX} 
                    y={frame.getY}
                    offsetX={frame.getWidth / 2}
                    offsetY={frame.getWidth / 2}
                >  
                    <Rect
                        width={frame.getWidth}
                        height={frame.getHeight} 
                        fill={ selected ? 'green' : '#222120'}
                        shadowBlur={5}
                        opacity={ selected ? 1 : 0.5}
                        shadowColor='black'
                        shadowOffset = { {x: 1, y: 1} }
                        shadowOpacity={0.7}
                    />
                    {src2 ? 
                        <Artwork 
                            src={src2} 
                            crossOrigin="anonymous"
                            width={frame.getWidth}
                            height={frame.getHeight} 
                        />
                    : null }
                    {selected ? 
                        <Circle 
                            x={frame.getWidth} 
                            y={0} 
                            radius={10} 
                            fill="#AC1737" 
                            onClick = {() => removeHandler(frame.getID)}
                        /> 
                    : null}
                </Group>
}
export default WBFrame