import {FC, useContext } from "react";
import AppContext from '../../store/context'
import styled from 'styled-components';
import { Attributes, Frame, Item, Product } from "../../api/models";
import {PictureFrame} from "../../api/models";

interface IProductProps {
    isOpen: boolean;
    handleToggleArtwork?: any;
};
const ProductWrapper = styled.div<IProductProps>`
    width: 100%;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    position: absolute;
    top: 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    background: ${props => props.theme.colors.secondary};
`;
const ProductCard = styled.div`
    width: 100%;
    max-width: 125px;
    padding: 10px;
    text-align: center;
    color: ${props => props.theme.colors.main};
`;
const ProductImage = styled.img`
    width: 100%;
`;
const ProductName = styled.div`
    width: 100%;
    font-size: 0.7em;
    text-align: left;
`;
const ProductList: FC<IProductProps> = ({isOpen, handleToggleArtwork}) => {
    const {state, setArtwork, addLineItems, addPictureFrame, updatePictureFrame } = useContext(AppContext);
    const { selectedFrame, frames, products, pictureFrames } = state;
    const handler = (product: Product) => {
       if(selectedFrame.length !== 0) {
            const selectedPictureFrame = pictureFrames.find((pfModal:PictureFrame ) => pfModal.frameID === selectedFrame)
            frames.map((frame : Frame) => frame.getID === selectedFrame ? frame.setArtwork(product) : null );
            let attributes: Array<Attributes> = [];
            attributes.push(new Attributes("TestKey", "TrestValue"));
            let items: Array<Item> = [];
            items.push(new Item(product.variants[0].id , 1, attributes));
            addLineItems(items);
            setArtwork(product.getID);
            if(handleToggleArtwork){
                handleToggleArtwork()
            }
           
            if(selectedPictureFrame === undefined){
                addPictureFrame(new PictureFrame(selectedFrame, product));
            }else{
                updatePictureFrame(new PictureFrame(selectedFrame, product));
            }
         // const clickk =  handleToggleArtwork
       }
    }
    return (
        <ProductWrapper isOpen={isOpen}>
            <>{products ? 
                products.map((product: Product, index: number) => 
                <ProductCard key={product.getID} onClick={() => handler(product)}>
                    <ProductImage src={products[index].variants[0].src} draggable={false} />
                    <ProductName>{product.getTitle}</ProductName>
                </ProductCard>)
                : null }</>
        </ProductWrapper>
    );
};

export default ProductList;