import { createContext } from 'react'
import IState from '../interface';

export const initialState: IState =  {
  products: [],
  frames: [],
  selectedFrame: [],
  selectedArtwork: [],
  wallImage: "",
  saveAsImage: false,
  checkoutID: "",
  webURL: "",
  pictureFrames:[]
}

/*
interface IContext {
  state: IState;
  addFrame: Dispatch<TAction>;
  removeFrame: Dispatch<TAction>;
  setWallImage: Dispatch<TAction>;
}*/

const AppContext = createContext<any>({
    state: initialState,
    addFrame: () => null,
    removeFrame: () => null,
    selectFrame: () => null,
    setSelectedArtwork: () => null,
    setWallImage: () => null,
    setSaveAsImage: () => null,
    createCheckout: () => null,
    getProducts: () => null,
    addPictureFrame: () => null,
    updatePictureFrame: () => null,
    removePictureFrame: () => null
});
  
export default AppContext
