import { useEffect, useState, useContext} from 'react';
import styled from 'styled-components';
import AppContext from '../../store/context'

const HeaderWrapper = styled.div`
    background-color: #fff;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #9e9e9e;
`;
const Logo = styled.img`
    margin: 20px;
    float:left;
`;
const ButtonSaveShare = styled.div`
    padding:15px;
    float:left;
    background: #fff;
    border: 1px solid #000;
    margin:10px;
    cursor: pointer;
    font-weight:bold;
    &:hover {
        color: ${props => props.theme.colors.main};
        background-color: ${props => props.theme.colors.pink};
     }
`;
const ButtonDownloadWall = styled.div`
    padding: 15px;
    float: left;
    margin: 10px;
    cursor: pointer;
    font-weight:bold;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
     }
`;
const ButtonAddToBag = styled.div`
    padding:15px;
    float:left;
    background-color: ${props => props.theme.colors.lightMaroon2};
    margin:10px;
    cursor: pointer;
    font-weight:bold;
    &:hover {
        color: ${props => props.theme.colors.main};
        background-color: ${props => props.theme.colors.pink};
     }
`;
const ButtonCart = styled.a`
    padding: 15px;
    float: left;
    background: #fff;
    border: 1px solid #000;
    margin:10px;
    cursor: pointer;
    font-weight: bold;
    &:hover {
        color: ${props => props.theme.colors.main};
        background-color: ${props => props.theme.colors.pink};
     }
`;
const ButtonWrapper = styled.div`
    float:right;
    margin: 5px;
`;
const Header = (): JSX.Element => {
    const [checkoutWebURL, setCheckoutWebURL] = useState<string>("")
    const {state, setSaveAsImage} = useContext(AppContext);
    const {frames, webURL} = state;
    useEffect(() => {
        if(checkoutWebURL === ""){
            setCheckoutWebURL(webURL)
        }
    },[webURL, checkoutWebURL])
    return  <HeaderWrapper>
                <Logo src="./svg/logo.svg" />
                <ButtonWrapper>
                    <ButtonSaveShare onClick={() => console.log("ddd")}>Save and Share</ButtonSaveShare>
                    <ButtonDownloadWall onClick={() => setSaveAsImage(true)}>Download you Wall</ButtonDownloadWall>
                    <ButtonDownloadWall onClick={() => console.log("ddd")}>Create a new Wall</ButtonDownloadWall>
                    <ButtonAddToBag onClick={() => console.log("ddd")}>Add to bag</ButtonAddToBag>
                    <ButtonCart href={checkoutWebURL} target="_blank">{frames.length}</ButtonCart>
                </ButtonWrapper>
            </HeaderWrapper>
}
export default Header;