import { FC } from 'react';
import styled from 'styled-components';

interface ICanvasSplash {

};
const UploadIcon = styled.img`
    display: inline-block !important;
    width: 100%;
    max-width: 100px;
    margin: 10px;
    text-align: center;
`;
const SplashWrapper = styled.div`
    width: 100%;
    margin: 10px;
    text-align: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    z-index: 9;
    cursor: pointer;
    &:hover {
        color: ${props => props.theme.colors.main};
        background-color: ${props => props.theme.colors.pink};
     }
`;
const ContentWrapper = styled.div`
    align-items: center;
`;
const SplashText = styled.div`
    width: 100%;
    margin: 10px;
    text-align: center;
`;
const SplashSubText = styled.div`
    width: 100%;
    margin: 10px;
    text-align: center;
`;

const CanvasSplash: FC<ICanvasSplash> = () => {

    return      <SplashWrapper>
                    <ContentWrapper>
                        <UploadIcon src={'./svg/upload.svg'} />
                        <SplashText>Drop your image here or select from your computer.</SplashText>
                        <SplashSubText>(JPG, PNG of tiffs allowed. Under 2MB.)</SplashSubText>
                    </ContentWrapper>
                </SplashWrapper>
}
export default CanvasSplash