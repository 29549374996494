import React from 'react';
import ReactDOM from 'react-dom';
import App from './screens/App';
import AppProvider from './store';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme'
import GlobalStyles from './styles/global';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <ThemeProvider theme={theme}>
          <App />
          <GlobalStyles />
        </ThemeProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
