import { FC, useContext, useState } from 'react';
import AppContext from '../../../store/context'
import styled from 'styled-components';
import { Frame } from '../../../api/models';


const CompositionButton = styled.div`
    display: flex;
    padding: 10px;
    color: #000;
    margin: 15px 0;
    padding-top:10px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    background-color: #F5F5F5;
    transition: all 0.5s;
    cursor:pointer;
    &:hover {
        color: ${props => props.theme.colors.secondary};
        background-color: ${props => props.theme.colors.main};
     }
`;

const CompositionText = styled.div`
    padding: 10px;
`;

const CompositionTitle = styled.div`
    font-size: 1.2em;
`;
const CompositionSubTitle = styled.div`
    font-size: 0.9em;
`;
const CompositionSelect = styled.div`
    padding: 10px;
    text-decoration: underline;
`;

const CompositionBackWrapper = styled.div`
    display:flex;
    border-bottom:1px solid #D7D7D7;
    padding-bottom: 10px;
    padding-top: 20px;
`;
const CompositionBackButton = styled.div`
    padding: 10px;
    text-align: left;
    text-decoration: underline;
    text-transform: uppercase;
    cursor:pointer;
    &:hover {
        color: ${props => props.theme.colors.offBlack};
     }
`;
const CompositionBackIcon = styled.div`
    padding: 9px 0 0 9px;   

`;
const ButtonSave = styled.div`
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color:#000;
    color: #fff;
    cursor:pointer;
    &:hover {
        color: ${props => props.theme.colors.main};
        background-color: ${props => props.theme.colors.secondary};
        border: 1px solid #000;
     }
`;


const IconSingleFrame = styled.svg`
  flex: none;
  width: 37px;
  height: 37px;
  background: url('./svg/singleframe.svg');
  ${CompositionButton}:hover & {
    background: url('./svg/singleframe_hover.svg');
  }
`;


const IconMultipleFrame = styled.svg`
  flex: none;
  width: 37px;
  height: 37px;
  background: url('./svg/multipleFrames.svg');
  ${CompositionButton}:hover & {
    background: url('./svg/multipleFrames_hover.svg');
  }
  
`;
interface IDetailProps {
    open: boolean;
  }
const CompositionSingleDetails = styled.div<IDetailProps>`
    display: ${prop => prop.open ? 'block' : 'none' }
`;

const CompositionMultipleDetails = styled.div<IDetailProps>`
    display: ${prop => prop.open ? 'block' : 'none' };
`;

interface IProps {
    nextSlide: any;
    prevSlide: any
};

const SelectyourComposition: FC<IProps> = (props) => {
    const {nextSlide, prevSlide} = props
    const {addFrame} = useContext(AppContext);
    const [singleOpen, setSingleOpen] = useState<boolean>(false)
    const [multipleOpen, setMultipleOpen] = useState<boolean>(false)
    const singleHandler =() => {
        setSingleOpen(!singleOpen)
    }
    const multipleHandler =() => {
        setMultipleOpen(!multipleOpen)
    }
    return   <div>

                <CompositionBackWrapper>
                    <CompositionBackIcon>&#60;</CompositionBackIcon><CompositionBackButton onClick={prevSlide}>Upload Your Wall</CompositionBackButton></CompositionBackWrapper>
        
                <h2>Select your composition</h2>
                <p>Select your composition below, you can custom make your own wall or use some of our preset compositions.</p>

                <CompositionButton onClick={singleHandler}>
                    <IconSingleFrame viewBox="0 0 20 20"></IconSingleFrame>
                    <CompositionText>
                        <CompositionTitle>Frame builder</CompositionTitle>
                        <CompositionSubTitle>Add single frames to your wall</CompositionSubTitle>
                    </CompositionText>
                    <CompositionSelect>Select</CompositionSelect>
                </CompositionButton>
                <CompositionSingleDetails  open={singleOpen}>
                <img 
                    src="/svg/frames/frame1.svg" 
                    alt="frame 1"
                    draggable="true" 
                    onClick={(e:any) => {
                        addFrame(new Frame("WBFrame-" + Math.floor(Math.random() * 1000000000), 100,200,100,120))
                      }}
                    />
                </CompositionSingleDetails>
                <CompositionButton  onClick={multipleHandler}>
                <IconMultipleFrame viewBox="0 0 20 20"></IconMultipleFrame>
                    <CompositionText>
                        <CompositionTitle>Preset compositions</CompositionTitle>
                        <CompositionSubTitle>Select from a few preset compositions</CompositionSubTitle>
                    </CompositionText>
                    <CompositionSelect>Select</CompositionSelect>
                </CompositionButton>
                <CompositionMultipleDetails open={multipleOpen}>
                <img 
                    src="/svg/frames/frame1.svg" 
                    draggable="true" 
                    alt="frame 2"
                    onClick={(e:any) => {
                        addFrame(new Frame("WBFrame-" + Math.floor(Math.random() * 1000000000), 100,200,100,120))
                      }}
                    />
                </CompositionMultipleDetails>
                <ButtonSave onClick={nextSlide}>Confirm</ButtonSave>
            </div>
}
export default SelectyourComposition;


