import {createGlobalStyle} from 'styled-components'

const GlobalStyles = createGlobalStyle`
*{
    box-sizing: border-box; 
}
*:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    margin: 0;
    outline: none;
  }
  #root{
  }
`;
export default GlobalStyles;