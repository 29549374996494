export default class Item {
    variantId: string;
    quantity: number;
    customAttributes:Array<Attributes>
    
    constructor(
        variantId: string,
        quantity: number,
        customAttributes:Array<Attributes>
     ){
        this.variantId = variantId;
        this.quantity = quantity;
        this.customAttributes = customAttributes;
    } 
}

export class Attributes {
    key:string;
    value:string;
    constructor (
        key: string,
        value: string
    ){
        this.value = value;
        this.key = key;
    }
    
}