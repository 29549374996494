import { useState, FC, useContext, useRef } from "react";
import AppContext from '../../../../store/context'
import styled from 'styled-components';

interface IUploadImageProps {

};
const Wrapper = styled.div`
    text-align: center;
`;

const UploadWrapper = styled.div`
    width: 100%;
    background-color: #F5F5F5;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin: 15px 0;
    > input {
       display: none;
      }
`;

const UploadIcon = styled.img`
    display: inline-block !important;
    width: 100%;
    max-width: 100px;
    margin: 10px;
    text-align: center;
`;

const UploadLabel = styled.p`
    width: 100%;
    margin: 10px auto;
    text-align: center;
    display: block;
`;

const PreviewImage = styled.img`
    width: 80%;
    padding: 5px;
    text-align: center;
    color: ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.main};
`;

const UploadImage: FC<IUploadImageProps> = () => {
    const {setWallImage} = useContext(AppContext);
    const [picture, setPicture] = useState<string>("");
    const inputRef =  useRef<any>(null);
    const handleFile = (e: any) => {
        const content = e.target.result;
        setPicture(content);
        setWallImage(content)
    }
    const handleError = (e: any) =>{
        console.log(e)
    }
    const handleChangeFile = (file: any) => {
        let fileData = new FileReader();
        fileData.onloadend = handleFile;
        fileData.onerror = handleError;
        fileData.readAsDataURL(file);
    }
  const triggerInput = () => {
      if(inputRef.current){
        inputRef.current.click();
      }
    }
  return <Wrapper>
            <UploadWrapper onClick={triggerInput}>
                <UploadIcon src={'./svg/upload.svg'} />
                <input ref={inputRef} type="file" accept=".png,.jpg" onChange={e => 
                    e.target.files ? handleChangeFile(e.target.files[0]) : null
                } /> 
                <UploadLabel>Upload your image</UploadLabel>
            </UploadWrapper>
            {picture ? <PreviewImage src={picture} /> : null  }
        </Wrapper>
};

export default UploadImage;