import { IData } from "../interfaces/IAPIResponseModel";
export default class Product {
    id:string;
    title:string;
    variants : IData[];
    constructor(id: string, 
                title: string,
                variants: IData[]
                ){
        this.id = id;
        this.title = title;
        this.variants = variants;
    }
    get getTitle(): string {
        return this.title;
    }
    get getID(): string {
        return this.id;
    }

}