import Product from "./product";

export default class Frame {
    id:string;
    width:number;
    height:number;
    x:number;
    y:number;
    artwork: Product | undefined;

    constructor(
        id:string,
        width:number,
        height:number,
        x:number,
        y:number
    ){
        this.id = id;
        this.width = width;
        this.height = height;
        this.x = x;
        this.y = y;
    }
    get getID(): string {
        return this.id;
    }
    get getWidth(): number {
        return this.width;
    }
    get getHeight(): number {
        return this.height;
    }
    get getX(): number {
        return this.x;
    }
    get getY(): number {
        return this.y;
    }
    get getArtwork(): Product | undefined {
        return this.artwork;
    }
    setArtwork(artwork:Product){
        this.artwork = artwork;
    }
}