import React, {useReducer, useCallback} from 'react'
import reducer from './reducers'
import AppContext, {initialState} from './context'
import { ActionType } from './actions/enums';
import { Frame, Item, PictureFrame, Product } from '../api/models';
import API from '../api';

const AppProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {checkoutID, webURL, products} = state
    const api = new API();
  
    const contextValue = {
      state:{...state},
      addFrame: (frame: Frame) => { dispatch({ type: ActionType.ADD_FRAME, payload: frame }) },
      removeFrame: (id: string) => { dispatch({ type:ActionType.REMOVE_FRAME, payload: id }) },
      selectFrame: (id: string) => { dispatch({ type: ActionType.SELECTED_FRAME, payload: id }) },
      setArtwork: (id: string) => { dispatch({ type: ActionType.SELECTED_ARTWORK, payload: id }) },
      setWallImage: (image: string) => { dispatch({ type: ActionType.SET_WALL_IMAGE, payload: image }) },
      setSaveAsImage:(flag: boolean) => { dispatch({ type: ActionType.SAVE_AS_IMAGE, payload: flag }) },
      createCheckout: useCallback(() => {
        const APIcheckout = api.createCheckout();
        APIcheckout.then((data) => {
            let id = data.id;
            let webUrl = data.webUrl;
            if(checkoutID === "" && webURL === ""){
              dispatch({ type: ActionType.SET_CHECKOUT_ID, payload: id });
              dispatch({ type: ActionType.SET_WEB_URL, payload: webUrl });
            }
          })
        },[]),
      getProducts: () => {
        const APIProducts = api.getProducts();
        if(products.length === 0){
          APIProducts.then((data: Array<Product>) => {
              dispatch({ type: ActionType.SET_PRODUCTS, payload: data });
          })
        }
      },
      addLineItems: (lineItemsToAdd: Array<Item>) => {
        if(checkoutID !== ""){
          api
            .addLineItems(checkoutID, lineItemsToAdd)
            .catch((e) =>  { throw new Error(`Adding a new line: ${e.message}`) })
        }
      },
      removeLineItems: (lineItemIdsToRemove: Array<string>) => {
        console.log("Remove From Cart")
        if(checkoutID !== ""){
          api
            .removeLineItems(checkoutID, lineItemIdsToRemove)
            .catch((e) =>  { throw new Error(`Adding a new line: ${e.message}`) })
        }
      },
      addPictureFrame: (pictureFrame: PictureFrame) => {
        dispatch({ type: ActionType.ADD_PICTURE_FRAME, payload: pictureFrame })
      },
      updatePictureFrame: (updateProduct: PictureFrame) => {
        dispatch({ type: ActionType.UPDATE_PICTURE_FRAME, payload: updateProduct })
      },
      removePictureFrame: (id: string) => {
        dispatch({ type: ActionType.REMOVE_PICTURE_FRAME, payload: id })
      }
    }
    return (
      <AppContext.Provider value={contextValue}>
        {children}
      </AppContext.Provider>
    )
  }
  export default AppProvider



  /**
   * 
   * 
   * 
   * function updateObjectInArray(array, action) {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item
    }
  })
}
   * 
   */