export enum ActionType {
    SET_WALL_IMAGE,
    SET_PRODUCTS,
    ADD_FRAME,
    REMOVE_FRAME,
    SELECTED_FRAME,
    SELECTED_ARTWORK,
    SAVE_AS_IMAGE,
    SET_CHECKOUT_ID,
    SET_WEB_URL,
    ADD_PICTURE_FRAME,
    UPDATE_PICTURE_FRAME,
    REMOVE_PICTURE_FRAME
  }