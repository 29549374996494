import { useEffect, useRef, useContext, useState  } from 'react';
import { Stage, Layer } from 'react-konva';
import AppContext from '../../store/context';
import WBImage from './WallImage';
import WBFrame from './Frame';
import { Frame, PictureFrame } from '../../api/models';
import CanvasSplash from './CanvasSplash';
import styled from 'styled-components';
const CanvasWrapper = styled.div`
  width: 100%;
  margin: 10px;
  position: relative;
`;
const Canvas = (): JSX.Element => {
    const [bgImage, setBGImage] = useState<string>("");
    const [frameList, setFrameList] = useState<Array<Frame>>([]);
    const [pictureFrameList, setPictureFrameList] = useState<Array<PictureFrame>>([]);
    const stageRef =  useRef<any>(null);
    const {state, removeFrame, selectFrame, addFrame, setSaveAsImage, removeLineItems} = useContext(AppContext);
    const {wallImage, frames, saveAsImage, pictureFrames}  = state

    const handleExportClick = () => {
      downloadURI(stageRef.current.getStage().toDataURL(),"yourWall.png")
    }
    useEffect(() => {
      setFrameList(frames)
    },[frames, setFrameList])

    useEffect(() => {
      setBGImage(wallImage)
    },[wallImage])

    useEffect(() => {
      if(saveAsImage){
        handleExportClick()
        setSaveAsImage(!saveAsImage)
      } 
    },[saveAsImage, setSaveAsImage])
    
    useEffect(() => {
      setPictureFrameList(pictureFrames)
    },[pictureFrames, setPictureFrameList])


    const handleOnDismiss = (id:string) => {
      console.log(id)
      removeLineItems([id]) ///Need to get the product Id remove from cart
      removeFrame(id)
    }

    return (<CanvasWrapper
              onDrop={(e) => {
                e.preventDefault();
                stageRef.current.setPointersPositions(e);
                const { x, y } = stageRef.current.getPointerPosition()
                addFrame(new Frame("WBFrame-" + Math.floor(Math.random() * 1000000000), 100, 200, x, y));
              }}
              onDragOver={(e) => {
                e.preventDefault()
              }}>
        <Stage  
          width={window.innerWidth - 400} 
          height={window.innerHeight}
          ref={stageRef}>
          { bgImage ? 
            <Layer>
              <WBImage base64Image={bgImage} /> 
            </Layer>
          : null }
          <Layer
            dragmove={(e:any) => {
              console.log(e)
            }}
          >
          { frameList.length !== 0 ?
                frameList.map((frame:Frame) => {
                  const pictureFrame = pictureFrameList.filter((item: PictureFrame) => item.getID === frame.getID)
                  return <WBFrame 
                            key={frame.getID}
                            frame={frame}
                            pictureFrame={pictureFrame[0]}
                            onSelected={(id:string) => selectFrame(id)}
                            onDismiss={(id:string) => handleOnDismiss(id)}
                          />
                })
            : null }
          </Layer>

        </Stage>
        </CanvasWrapper>);
}
export default Canvas;

function downloadURI(uri:string, name:string) {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  //delete link;
}