
import { FC, useState } from 'react';
import styled from 'styled-components';

const WrapperWallCalcualtor = styled.div`
    width: 100%;
    padding: 10px;
    text-align: center;
`;
const ButtonSave = styled.div`
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color:#000;
    color:#fff;
    cursor:pointer;
`;

const FormWrappper = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    text-align: left;
`;
const FormInput = styled.input`
    width: 95%;
    padding: 10px;
`;
interface IProps {
    nextSlide: any;
};

const WallSizeCalculator: FC<IProps> = (props) => {
const [width, setWidth] = useState<string>("");
const [height, setHeight] = useState<string>("");
const {nextSlide} = props

/*const {addFrame} = useContext(AppContext);

useEffect(() => {
    if(width !== "" && height !== ""){
       // addFrame(new Frame("WBFrame-" + Math.floor(Math.random() * 1000000000), 100,200,100,120))
    }
},[width, height])*/

const saveHandler = () => {
    if(width || height){
         nextSlide()
    }
}
return  <WrapperWallCalcualtor>
            <div>cm | in</div>
            <FormWrappper>
                <div>
                    <label>Wall Width</label>
                    <FormInput type="text" placeholder="Wall Width.." onChange={e => setWidth(e.target.value.toString())} />
                </div>
                <div>
                    <label>Wall Height</label>
                    <FormInput type="text" placeholder="Wall Height.."  onChange={e => setHeight(e.target.value.toString())} />
                </div>
            </FormWrappper>

            <ButtonSave onClick={() => saveHandler() }>Save</ButtonSave>
        </WrapperWallCalcualtor>
}
export default WallSizeCalculator;