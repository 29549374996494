import { FC } from 'react';

interface IPictureFrame {
    image:string;
    width:number;
    height:number;
}

const PictureFrame: FC<IPictureFrame> = ({image, width, height}) => {

    const imageWidth = width * 0.7;
    const imageHeight = height * 0.7;
    const x = -11;
    const y = 8;
    return   <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} >
                <g id="Group_975" data-name="Group 975" >
                    <g id="Rectangle_275" data-name="Rectangle 275">
    {image ?                     <image 
                                    href={image}
                                    width={imageWidth} 
                                    height={imageHeight}
                                    x={x}
                                    y={y}
                                /> 
                    : null}

                        <rect id="Rectangle_302" data-name="Rectangle 302" width="100%" height="100%" fill="none" stroke="#333" strokeMiterlimit="10" strokeWidth="1"/>
                    </g>
                </g>
            </svg>

}
export default PictureFrame;