import IState from '../interface';
import TActions, {updatePictureFrame} from '../actions';
import {ActionType} from '../actions/enums'
import { Frame, PictureFrame } from '../../api/models';

function reducer(state: IState, action: TActions): IState {
    const {type, payload} = action
    switch(type){
        case ActionType.SET_PRODUCTS:
            return {...state, products: payload} 
        case ActionType.ADD_FRAME:
            return {...state, frames: [...state.frames, payload]}
        case ActionType.SELECTED_FRAME:
            return {...state, selectedFrame: payload}
        case ActionType.REMOVE_FRAME:
            let removeFrameIndex = state.frames.map((item:Frame) => item.getID).indexOf(action.payload);
            return {    
                ...state, 
                frames:[
                    ...state.frames.slice(0, removeFrameIndex),
                    ...state.frames.slice(removeFrameIndex + 1)
                ]
            }
        case ActionType.SELECTED_ARTWORK:
                return {...state, selectedArtwork: payload}
        case ActionType.SET_WALL_IMAGE:
            return {...state, wallImage: payload} 
        case ActionType.SAVE_AS_IMAGE: 
            return {...state, saveAsImage: payload}
        case ActionType.SET_CHECKOUT_ID: 
            return {...state, checkoutID: payload}
        case ActionType.SET_WEB_URL: 
            return {...state, webURL: payload}
        case ActionType.ADD_PICTURE_FRAME: 
            return {...state, pictureFrames: [...state.pictureFrames, payload]}
        case ActionType.REMOVE_PICTURE_FRAME:
            let removePictureFrameIndex = state.frames.map((item:Frame) => item.getID).indexOf(action.payload);
            return {
                ...state, 
                pictureFrames: [
                    ...state.pictureFrames.slice(0, removePictureFrameIndex),
                    ...state.pictureFrames.slice(removePictureFrameIndex + 1)
                ]
            }
        case ActionType.UPDATE_PICTURE_FRAME: 
            return {
                ...state,
                pictureFrames: state.pictureFrames.map((pictureFrame: PictureFrame) =>  {
                    if(pictureFrame.frameID === state.selectedFrame){
                        return payload
                    }else{
                          return pictureFrame; 
                    }
                  
                })
                
                /*,
                pictureFrames: state.pictureFrames.map((pictureFrame: PictureFrame) => 
                            pictureFrame.getID === state.selectedFrame ?
                    // transform the one with a matching id
                    { pictureFrames: payload } : 
                    // otherwise return original todo
                    pictureFrame
                ) */
        }
        
        
        
        

        default:
            return state
    }
}
export default reducer;