import Product from "./product";

export default class PictureFrame {
    frameID: string;
    product: Product;    
    constructor(
        frameID: string,
        product: Product,
     ){
        this.frameID = frameID;
        this.product = product;
    } 
    set setProduct(product: Product) {
        this.product = product;
     }
    get getID(){
        return this.frameID;
    }
}
